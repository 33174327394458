import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/postLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`How to Use DefiYieldCalculator.com`}</h1>
    <ul>
      <li parentName="ul">{`Put in the values`}</li>
      <li parentName="ul">{`Hit submit`}</li>
      <li parentName="ul">{`Repeat with best/worst case scenarios`}</li>
    </ul>
    <h3>{`Gas Costs`}</h3>
    <p>{`Including gas costs is entirely optional - just leave or 0 them out if you'd rather not consider them.`}</p>
    <p>{`The gas cost associated with compounding (or dumping) is made up of fixed and variable costs. I have ball-parked these for normal conditions on Arbitrum.`}</p>
    <p>{`Fixed costs don't change with reward size, and normally include:`}</p>
    <ol>
      <li parentName="ol">{`Claiming Rewards`}</li>
      <li parentName="ol">{`Swapping (normally half of the reward size)`}</li>
      <li parentName="ol">{`Adding Liquidity`}</li>
      <li parentName="ol">{`Staking Liquidity`}</li>
      <li parentName="ol">{`Anything Else`}</li>
    </ol>
    <p>{`Variable costs change with reward size, and normally include the liquidity fee for swapping between tokens - e.g. 0.3% of swapped amount on sushiswap BEFORE price impact.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      